*, *:after, *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'GTAmerica';
    src: url("fonts/GT-America-Expanded-Medium.otf") format("opentype");
}

@font-face {
    font-family: 'WTMonarchNova';
    src: url("fonts/WT_Monarch_Nova.ttf") format("truetype");
}
